<template>
    <div class="paginacao-tabela d-flex align-items-end">
        <div class="d-flex paginacao-tabela-totais">
            <span> {{ totalDeResultados }} encontrados </span>
        </div>
        <div class="d-flex paginacao-tabela-paginas paginacao-tabela-scroll-x">

            <span 
                class="paginacao-tabela-botao-paginacao"
                v-for="(pagina, i) in paginas"
                :key="i"
                :class="pagina==numeroDaPaginaAtual?'selecionado':''"
                @click="$emit('click', pagina)"
            > 
                {{ pagina }} 
            </span>

        </div>
    </div>
</template>

<script>

export default {
    name:'PaginacaoTabela',

    props:{
        totalDePaginas: {
            type: Number,
            default: 0,
        },
        numeroDaPaginaAtual: {
            type: Number,
            default: 0,
        },
        totalDeResultados: {
            type: Number,
            default: 0,
        },
        loading: {
            type: Boolean,
            default: false
        },
    },

    computed: {
        paginas() {
            return Array.from({length: this.totalDePaginas}, (_, i) => i + 1)
        },
    }
}
</script>

<style scope>

.paginacao-tabela {
    color: var(--cinza-5);
    font-weight: bold;
    height: 30px;
}

.paginacao-tabela-totais {
    width: 20%;
}

.paginacao-tabela-paginas {
    width: 60%;
    padding-bottom: 6px;
}

.paginacao-tabela-botao-paginacao {
    display: inline-block;
    cursor: pointer;
    padding: 2px 4px;
    margin-right: 4px;
    margin-left: 4px;
}
    .paginacao-tabela-botao-paginacao:hover {
        text-decoration: underline;
    }

.paginacao-tabela-botao-paginacao.selecionado {
    text-decoration: underline;
}
    .paginacao-tabela-botao-paginacao.selecionado:hover {
        text-decoration: none;
    }

.paginacao-tabela-scroll-x {
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: grey lightgrey;
}

    .paginacao-tabela-scroll-x::-webkit-scrollbar-track {
        background: lightgrey;
        border-radius: 0.15em;
    }

    .paginacao-tabela-scroll-x::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 0.15em;
    }

    .paginacao-tabela-scroll-x::-webkit-scrollbar {
        width: .3em;
        height: .3em;
    }

</style>